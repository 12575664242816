import {
  Component,
  Input,
  OnChanges,
  OnInit,
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EventManagerService } from "../../service/event-manager.service";

@Component({
  selector: "input-note-popup",
  styleUrls: ["./input-note-popup.component.scss"],
  templateUrl: "./input-note-popup.component.html",
})
export class InputNotePopupComponent implements OnInit, OnChanges
{
  @Input() id: any;
  @Input() value: any;
  @Input() maxLength: number = 300;
  @Input() isReadonly: boolean = false;
  constructor(
    public eventManager: EventManagerService,
    public activeModal: NgbActiveModal,
    public ngModal: NgbModal
  ) {
  }

  ngOnInit() {}
  ngOnChanges(): void {}
  dismiss() {
    this.activeModal.dismiss();
  }
  save() {
    this.dismiss();
    setTimeout(() => {
      this.eventManager.broadcast({
        name: 'emit-data-input-note-popup',
        content: {
          id: this.id,
          value: this.value
        }
      })
    }, 10)
    
  }
}
