import { AfterViewInit, Component, OnDestroy, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Constant } from "../../constant";
import { of, Subscription } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppQueries, Dispatcher, SetTimeOTP } from 'src/app/state';
import { ActivatedRoute } from "@angular/router";
import { EventManagerService } from "../../service/event-manager.service";
import { EditComponent } from "../../extend-code/edit.component";
import { DialogService } from "../../service/dialog.service";
import { DateUtil } from "../../utils/date.util";
import { ShortcutService } from "../../service/shortcut.service";
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
    selector: 'new-otp',
    templateUrl: "./otp.component.html",
    styleUrls: ["./otp.component.scss"]
})

export class NewOTPComponent extends EditComponent implements OnInit, OnDestroy {
    @Output() result: EventEmitter<any> = new EventEmitter();
    @Input() phone: any;
    @Input() phonePostal: any;
    @Input() isRegister: boolean = false;
    @Input() isResend: boolean = true;
    @Input() startTime: any;
    @Input() bank: any = {};
    @Input() data: any = {};
    @Input() note: any;
    @Input() func: any;
    @Input() broadcast: any;
    @Input() defaultTime = 90;
    otp: string = '';
    failTimes = 0;
    countdown = 10;
    myInterval: any;
    accountInfo: any;
    theme: any;
    subscription: Subscription[] = [];
    Constant = Constant;
    isInvalidOTP: boolean = false;
    constructor(
        public eventManager: EventManagerService,
        public route: ActivatedRoute,
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private dialogService: DialogService,
        private dispatcher: Dispatcher,
        private appQueries: AppQueries
    ) {
        super(eventManager);
    }
    ngOnInit() {
        ShortcutService.addOneKey(13, this.save.bind(this));
        this.subscription = [
            this.eventManager.subscribe('set-data-otp', (res) => {
                this.func = res.content.func || this.func;
                this.phone = res.content.phone || this.phone || '';
                this.phone = this.phone.substring(0, 4) + '***' + this.phone.substring(7, this.phone.length);
                this.data = res.content.data || this.data;
                this.otp = '';
                this.note = res.content.note || this.note;
                this.startTime = Date.now();
                this.countdown = Math.floor((this.startTime + this.defaultTime * 1000 - Date.now()) / 1000);
                ShortcutService.addOneKey(13, this.save.bind(this));
            }),
            this.eventManager.subscribe('close-modal', (res) => { this.dismiss() }),
            this.eventManager.subscribe('invalid-otp', (res) => { this.invalidOTP(); }),
            this.appQueries.userProfile$
                .subscribe((userProfile) => {
                    this.accountInfo = userProfile || {};
                    this.phone = this.phone || this.accountInfo.phone;
                    this.phonePostal = this.phonePostal || this.accountInfo.phonePostal ; 
                    this.bank = this.accountInfo.bankAccount;
                }),
            this.appQueries.theme$
                .subscribe((theme) => {
                    this.theme = theme;
                })
        ];
        this.startTime = this.startTime || Date.now();
        this.phone = this.phone || '';
        this.phone = this.phone.substring(0, 4) + '***' + this.phone.substring(7, this.phone.length);
        this.countdown = Math.floor((this.startTime + this.defaultTime * 1000 - Date.now()) / 1000);
        this.myInterval = setInterval(() => {
            if (this.countdown > 0) {
                this.countdown--;
            }
        }, 1000);
        super.ngOnInit();
    }
    ngOnDestroy() {
        console.log('destroy otp: ', DateUtil.parseDate(Date.now(), 'HH:mm:ss'));
        this.eventManager.destroys(this.subscription);
        clearInterval(this.myInterval);
        this.eventManager.broadcast({
            name: 'action-destroy-otp',
            content: {
                countdown: this.countdown,
                date: Date.now()
            }
        })
    }
    dismiss() {
        ShortcutService.removeFuncEsc();
        this.activeModal.dismiss();
    }
    validate() {
        return this.otp && (this.otp + '').length === 6 && this.countdown != 0;
    }
    confirm() {
        if (!this.validate()) {
            return of(false);
        }
        const data = Object.assign({}, this.data || {});
        data.otp = this.otp + '';
        this.result.emit(data);
        this.isInvalidOTP = false;
        this.executeCallAPI('SEND');
    }
    callAPI(event) {
        if (event === 'SEND') {
            this.executeFunction();
        } else {
            this.excuteResendOTP();
        }

    }
    executeFunction() {
        const data = Object.assign({}, this.data || {});
        data.otp = this.otp + '';
        this.eventManager.broadcast({
            name: 'result-otp',
            content: data,
        });
    }
    invalidOTP() {
        this.otp = '';
        this.isInvalidOTP = true;
        this.failTimes = this.failTimes >= 0 ? this.failTimes : 0;
        this.failTimes++;
        if (this.failTimes === 3) {
            if (this.accountInfo.id) {
                this.dialogService.showError('OTP hết hiệu lực do nhập sai quá 3 lần. Vui lòng thử lại.', '', { backdrop: 'static' })
                    .then(() => {
                        ShortcutService.removeFuncEsc();
                        this.modalService.dismissAll();
                    })
            } else {
                this.dialogService.showError('Sai OTP quá 3 lần. Vui lòng tạo đăng ký mới.')
            }
        }
    }
    cancel() {
        this.result.emit(null);
    }
    resetCoutdown() {
        this.dispatcher.fire(new SetTimeOTP(Date.now()));
        this.countdown = this.defaultTime;
    }
    resendOTP() {
        if (!this.isResend) {
            this.excuteResendOTP();
            return;
        }
        this.otp = '';
        this.isInvalidOTP = false;
        // this.authService.resendOTP(this.data)
        //     .subscribe((res: any) => {
        //         this.data = res.data;
        //         this.startTime = Date.now();
        //         this.countdown = Math.floor((this.startTime + this.defaultTime * 1000 - Date.now()) / 1000);
        //     })
    }
    excuteResendOTP() {
        this.eventManager.broadcast({
            name: 'resend-otp',
        });
        this.startTime = Date.now();
        this.phone = this.phone.substring(0, 4) + '***' + this.phone.substring(7, this.phone.length);
        this.countdown = Math.floor((this.startTime + this.defaultTime * 1000 - Date.now()) / 1000);
    }
    getClass() {
        if (!this.isRegister) {
            return '';
        }
        if (!this.theme || (this.theme && this.theme.isDefault)) {
            return 'XACTHUCDKY';
        }
        return 'XACTHUCDKY_' + this.theme.name;
    }
}
