import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiTrackingService {
  private _onAPISucess = new Subject<string | null>();
  private _onAPIFailed = new Subject<string | null>();
  private _onShowSpinner = new Subject<boolean>();
  private _onShowSpinnerProcessing = new Subject<number>();
  constructor() {}

  public onShowSpinner(): Observable<boolean> {
    return this._onShowSpinner.asObservable();
  }
  public showSpinner(start: boolean) {
    this._onShowSpinner.next(start);
  }

  public onShowSpinnerProcessing(): Observable<number> {
    return this._onShowSpinnerProcessing.asObservable();
  }
  public showSpinnerProcessing(percent: number) {
    this._onShowSpinnerProcessing.next(percent);
  }

  public onAPISucess(): Observable<string | null> {
    return this._onAPISucess.asObservable();
  }
  public raiseAPISucess(requestData: string | null) {
    this._onAPISucess.next(requestData);
  }

  public onAPIFailed(): Observable<string | null> {
    return this._onAPIFailed.asObservable();
  }
  public raiseAPIfailed(errorData: string | null) {
    this._onAPIFailed.next(errorData);
  }
}
