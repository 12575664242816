<div class="input-date d-flex align-items-center" id="input-group-{{id}}">
  <div class="text-show" id="text-show-{{id}}" *ngIf="textShow">
    {{textShow}}
  </div>
  <!-- placeholder="{{placeholder}}" -->
  <input placeholder="{{placeholder}}" class="form-control keyboard-input prevent-select" type="text" [(ngModel)]="text"
    [ngClass]="isError ? 'border-red' : ''" [disabled]="disabled" (keydown)="catchKeydown($event)">
  <input placeholder="{{placeholder}}" class="form-control" type="text" [(ngModel)]="value" id="datepicker{{id}}"
    #datePicker>
  <button class="btn" (click)="datePicker.click()" [disabled]="disabled">
    <img *ngIf="image" [src]="image" width="20" height="20">
    <svg *ngIf="!image" width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 1V5M6 1V5M1 9H19M3 3H17C18.1046 3 19 3.89543 19 5V19C19 20.1046 18.1046 21 17 21H3C1.89543 21 1 20.1046 1 19V5C1 3.89543 1.89543 3 3 3Z"
        stroke="#30363D" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </button>
</div>