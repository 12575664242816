import {
  Component,
  Input,
  OnChanges,
  OnInit,
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EventManagerService } from "../../service/event-manager.service";

@Component({
  selector: "view-note-popup",
  styleUrls: ["./view-note-popup.component.scss"],
  templateUrl: "./view-note-popup.component.html",
})
export class ViewNotePopupComponent implements OnInit, OnChanges
{
  @Input() id: any;
  @Input() value: any;
  @Input() backgroundColor: any;
  @Input() color: any;
  @Input() title: any;
  constructor(
    public eventManager: EventManagerService,
    public activeModal: NgbActiveModal,
    public ngModal: NgbModal
  ) {
  }

  ngOnInit() {}
  ngOnChanges(): void {}
  dismiss() {
    this.activeModal.dismiss();
  }
}
