import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, Injector, Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { debounceTime, tap } from 'rxjs/operators';
import { EventManagerService } from '../service/event-manager.service';
import { Constant } from '../constant';
import { DateUtil } from '../utils/date.util';
import { SubscriptionService } from '../service/subscription.service';

@Injectable({ providedIn: 'any' })
export abstract class EditComponent implements OnInit, OnDestroy {
  @Input() queryParams: any = {};
  id: any;
  editForm: any;
  detailObject: any = {};
  errors: any = {};
  isSaved: boolean = false;
  isCallingAPI: boolean = false;
  isUpload: boolean = false;
  isChanged: boolean = false;
  isHasValue: boolean = false;
  subscriptions?: Subscription[] = [];
  subsCommon?: Subscription[] = [];
  callApi$ = new Subject();
  checkChange$ = new Subject();
  calculateChange$ = new Subject();
  errorMessage: string = '';
  public Constant = Constant;
  subscribeState: any;
  constructor(
    public eventManager: EventManagerService,
  ) {
  }
  ngOnInit() {
    this.subscribeState = SubscriptionService.register(2);
    this.subsCommon = [
      this.eventManager.subscribe('upload-file', (res) => {
        this.isUpload = true;
      }),
      this.eventManager.subscribe('upload-fail', (res) => {
        this.isUpload = true;
      }),
      this.callApi$.pipe(
        debounceTime(500),
        ).subscribe((event)=> { return this.callAPI(event);}),
      this.checkChange$.pipe(
        debounceTime(500),
        ).subscribe((event)=> { return this.checkChange();}),
      this.calculateChange$.pipe(
        debounceTime(1000),
      ).subscribe((event)=> { return this.calculateTotal();}),
      this.subscribeState.complete().subscribe(() => {
        this.parseData();
      }),
    ];
  }
  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.eventManager.destroys(this.subscriptions);
    }
    this.eventManager.destroys(this.subsCommon);
    // this.dismissAllModal();
  }
  dismissAllModal() {
    this.eventManager.broadcast({name: 'dismiss-all-modal'});
  }
  onSuccess(res) {}
  onError(error) {}
  updateForm() {}
  calculateTotal() {}
  parseData() {}
  disabledButton() {
    const keys = Object.keys(this.errors);
    const key = keys.find(key => this.errors[key]);
    return key ? true : false;
  }
  callAPI(event?) {
    this.save();
  }
  executeCallAPI(event?) {
    this.callApi$.next(event || true);
  }
  patchValue(key, value) {
    this.editForm.patchValue({
      [key]: value
    })
    this.checkChange$.next(true);
  }
  patchDate(key, value) {
    const date = DateUtil.parseDate( DateUtil.revertDate(value, 'dd/MM/yyyy'), 'yyyyMMdd');
    if (value && date !== this.editForm.value[key]) {
      this.editForm.patchValue({
        [key]: date
      })
    }
  }
  beforeSave() {
    this.isSaved =  true;
    const values = this.editForm.value;
    Object.keys(values).forEach((key) => {
      if (values[key] === 'undefined') {
        const object = {};
        object[key] = undefined;
        this.editForm.patchValue(object);
      }
    })
    const result = this.disabledButton();
    if (result || this.editForm.invalid) {
      return true;
    }
  }
  save() {
    if (this.beforeSave()) {
      return;
    }
  }
  startCalcualteChange() {
    this.calculateChange$.next(true);
  }
  startCheckChange() {
    this.checkChange$.next(true);
  }
  checkIsChanged() {
    return false;
  }
  checkChange() {
    this.isChanged = this.checkIsChanged();
  }
  checkDisabled() {
    return this.isHasValue && !this.isChanged;
  }

}
