import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { DateUtil } from '../utils/date.util';
import { ApiTrackingService } from '../service/api-tracking.service';

@Directive({
  selector: '[prevent-multi-clicks]',
})
export class PreventMultiClicksDirective implements OnInit, OnDestroy {
  private _lastClick!: Date;
  private destroy$ = new Subject<boolean>();


  @Input('block-ui-until') _blockUntil = '';
  @Input('disabled-until') _disabledUntil = '';
  @Input() inactive = false;


  private tagName = "button";

  constructor(private elRef: ElementRef, private _apiTrackingService: ApiTrackingService, private render: Renderer2) {
    if (elRef.nativeElement) {
      this.tagName = elRef.nativeElement.localName || elRef.nativeElement.tagName;

      if (this.tagName) {
        this.tagName = this.tagName.toLowerCase();
      }
    }

  }

  ngOnInit() {
    const el = this.elRef.nativeElement;
    fromEvent(el, 'click', { capture: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe((e: any) => {
          if (this.inactive) return;
          if (el.disabled) { return; }
          // console.log('click: ', DateUtil.parseDate(new Date(), 'mm:ss'));
          if (this._lastClick && (Date.now() - this._lastClick.getTime()) < 1000) {
            e.preventDefault();
            e.stopImmediatePropagation();
            // console.log('prevent click: ');
          } else {

            if (this._blockUntil) {
              this.render.addClass(this.elRef.nativeElement, 'disabled-btn');
            } else if (this._disabledUntil) {
              this.render.addClass(this.elRef.nativeElement, 'disabled-btn');
            }
            this._lastClick = new Date();
          }
      });

    if (this._blockUntil || this._disabledUntil) {
      this._apiTrackingService.onAPISucess()
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => {
        this.onRequestEnd(x);
      });

      this._apiTrackingService.onAPIFailed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => {
        this.onRequestEnd(x);
      });
    }
  }

  private onRequestEnd(requestInfor: string | null) {
    if (this._blockUntil && requestInfor && requestInfor.indexOf(this._blockUntil) >= 0) {
      this.render.removeClass(this.elRef.nativeElement, 'disabled-btn');
    } else if (this._disabledUntil && requestInfor && requestInfor.indexOf(this._disabledUntil) >= 0) {
      this.render.removeClass(this.elRef.nativeElement, 'disabled-btn');
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
