import {AfterViewInit, Component, OnDestroy, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef} from "@angular/core";
import { Constant } from "../../constant";
import  Pikaday from 'pikaday';
import { DateUtil } from "../../utils/date.util";
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: 'input-date',
  templateUrl: "./input-date.html",
  styleUrls: ['./input-date.scss'],
})

export class InputDateComponent implements OnInit, AfterViewInit, OnChanges {
    @Input('id') id: string = '' + Math.round(Math.random() * 1000) + Math.round(Math.random() * 1000);
    @Input('value') value: any;
    @Input('min') min: any;
    @Input('max') max: any;
    @Input('placeholder') placeholder: any = 'dd/mm/yyyy';
    @Input('format') format: string = 'dd/MM/yyyy';
    @Input('disabled') disabled: boolean = false;
    @Input('isError') isError: boolean = false;
    @Input('isNoPast') isNoPast: boolean = false;
    @Input('isNoCurrentDate') isNoCurrentDate: boolean = false;
    @Input('isNoFuture') isNoFuture: boolean = false;
    @Input('image') image: any;
    @Output() result: EventEmitter<any> = new EventEmitter();
    @Output() valueChange: EventEmitter<any> = new EventEmitter();
    Constant = Constant;
    picker: any;
    date: any;
    valueOutput: any;
    text: string = '';
    textInput: string = '';
    textShow: string = '';
    constructor(
        private cdf: ChangeDetectorRef

    ) {

    }
    ngOnInit() {
        this.id = Math.floor(Math.random() * 1000) + '-' + Math.floor(Math.random() * 1000);
        $(window).keydown((event) => {
            const selection: any = document.getSelection();
            if (selection.toString() && selection.extentNode.id === 'input-group-' + this.id ) {
                this.catchKeydownTextShow(event)
                return;
            }
        });
    }
    ngAfterViewInit(): void {
        const yearRange = [1900, 2100];
        if  (this.isNoPast) {
            const currentDate = DateUtil.getCurrentDateNoTime();
            if (this.isNoCurrentDate) {
                currentDate.setDate(currentDate.getDate() + 1);
            }
            this.min = currentDate.getTime();
            yearRange[0] = new Date().getFullYear();
        }
        if  (this.isNoFuture) {
            const currentDate = DateUtil.getCurrentDateNoTime();
            if (this.isNoCurrentDate) {
                currentDate.setDate(currentDate.getDate() - 1);
            }
            this.max = Date.now();
            yearRange[1] = new Date().getFullYear();
        }
        const func = this.changeValue.bind(this);
        this.picker = new Pikaday({
            field: document.getElementById('datepicker' + this.id),
            format: this.format,
            toString(date, format) {
                func(date.getTime());
                return DateUtil.parseDate(date, this.format);
            },
            parse(dateString, format) {
                const parts = dateString.split('/');
                const day = parseInt(parts[0], 10);
                const month = parseInt(parts[1], 10) - 1;
                const year = parseInt(parts[2], 10);
                return new Date(year, month, day);
            },
            minDate: this.min ? new Date(this.min) : undefined,
            maxDate: this.max ? new Date(this.max) : undefined,
            yearRange,
            keyboardInput: true
        });
        this.moveToDate();
        $('#text-show-' + this.id).on('keydown', (event) => {
            this.catchKeydownTextShow(event)
        });
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes.value) {
            const value = changes.value.currentValue;
            if (!value) {
                // this.textShow = this.placeholder;
                return;
            }
            this.date =  typeof value === 'string' ? DateUtil.revertDate(value , this.format) : new Date(value);
            this.moveToDate();
            this.textInput = DateUtil.parseDate(this.date , this.format) ;
            this.text = this.textInput ?  '  ' : undefined;
            this.parseTextShow();
        }
    }
    moveToDate() {
        if (this.picker) {
            const minDate = this.min ? new Date(this.min) : undefined;
            const maxDate = this.max ? new Date(this.max) : undefined;
            if ((!minDate || minDate <= this.date) && (!maxDate || this.date <= maxDate) ) {
                this.picker.setDate(DateUtil.parseDate(this.date, 'yyyy-MM-dd'));
            }
        }
    }
    changeValue(val) {
        const value  = DateUtil.parseDate(val, this.format);
        if (value === this.value) {
            return;
        }
        this.textInput = value;
        this.value = value;
        this.parseTextShow();
    }
    // changeInput() {
    //     const date = DateUtil.revertDate(this.textInput, this.format);
    //     this.value = date.getTime();
    //     this.valueChange.emit(this.value);
    //     setTimeout(() => {
    //         this.result.emit(this.value);
    //     }, 200)
    // }
    catchKeydown(event) {
        const key = event.key;

        if (key === 'Backspace' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Tab' || key === 'Delete') {
          
            if (key === 'Backspace' || key === 'Delete') {
                this.textInput = '';
                setTimeout(() => {
                    this.parseTextShow();
                }, 100);
            }
            return;
        }

        if (key >= '0' && key <= '9') {
            this.textInput += key;
            this.parseTextShow();
        }
        event.preventDefault();
    }
    parseTextShow() {
        let textNumber = (this.textInput || '').replaceAll('/', '');
        let day: any = textNumber.substring(0,2);
        if (parseInt(day) > 31) {
            day = '31';
        }
        let month: any = textNumber.substring(2,4);
        if (parseInt(month) > 12) {
            month = '12';
        }
        let textParse = `${day}${month ? '/' + month : ''}${textNumber.substring(4,8) ? '/' + textNumber.substring(4,8) : ''}`;
        this.textShow = textParse;
        setTimeout(() => {
            this.text = this.textInput ?  '  ' : undefined;
        }, 10)

        if (this.textShow.length === 10) {
            this.valueChange.emit(this.textShow);
            setTimeout(() => {
                this.result.emit(this.textShow);
            }, 200)
        }
        this.cdf.detectChanges();
    }
    catchKeydownTextShow(event) {
        const key = event.key;
        if (key === 'Backspace' || key === 'Delete') {
            this.textInput = '';
            setTimeout(() => {
                this.parseTextShow();
            }, 10);
        }
    }
}
