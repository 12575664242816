import {AfterViewInit, Component, OnDestroy, OnInit, Input, Output, EventEmitter} from "@angular/core";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constant } from '../../constant';
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: 'error-popup',
  templateUrl: "./error-popup.html",
  styleUrls: ["./error-popup.scss"]
})

export class ErrorPopupComponent {
    @Output() result: EventEmitter<any> = new EventEmitter();
    @Output() message: any;
    @Input() title: any;
    @Input() isOkButton: any;
    @Input() iconImage: any = './assets/images/icons/error.png';
    @Input() isDismissAll: boolean;
    public Constant = Constant;
    constructor(
        public activeModal: NgbActiveModal,
        public ngModal: NgbModal
    ) {}
    dismiss() {
        if (!this.isDismissAll) {
            this.activeModal.dismiss();
        } else {
            this.ngModal.dismissAll();
        }
        this.result.emit(true);
    }
}
