import {AfterViewInit, Component, OnDestroy, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef} from "@angular/core";
import { Constant } from "../../constant";
import { Subject, Subscription, debounceTime } from 'rxjs';
import { NumberUtil } from "../../utils/number.util";
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: 'input-number',
  templateUrl: "./input-number.html",
  styleUrls: ['./input-number.scss'],
})

export class InputNumberComponent implements OnInit, OnChanges {
    @Input('amount') amount: any;
    @Input('unit') unit: any;
    @Input('maxLength') maxLength: any;
    @Input('placeholder') placeholder: any;
    @Input('disabled') disabled: boolean = false;
    @Input('isDecimal') isDecimal: boolean = false;
    @Input('maxLengthDecimal') maxLengthDecimal: any;
    @Input('isInteger') isInteger: boolean = true;
    @Input('isSeparate') isSeparate: boolean = true;
    @Input('isError') isError: boolean = false;
    @Output() amountChange: EventEmitter<any> = new EventEmitter();
    @Output() result: EventEmitter<any> = new EventEmitter();
    amountText: string = '';
    Constant = Constant;
    changeInputText$ = new Subject();
    beforeKeydown: any;
    @Input('id') id: string = '' + Math.round(Math.random() * 1000) + Math.round(Math.random() * 1000);
    constructor(
        private cdf: ChangeDetectorRef
    ) {

    }
    ngOnInit() {
        this.changeInputText$.pipe(
            debounceTime(100),
            ).subscribe((event)=> { return this.parseData();});
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes.amount) {
            this.changeText();
        }
    }
    changeValue() {
        this.changeInputText$.next(true);
    }
    parseData() {
        this.checkValidateData();
        this.amountChange.emit(this.amount);
        setTimeout(() => {
            this.result.emit(this.amount);
        }, 10)
    }
    checkValidateData() {
        if (this.amountText === '') {
            this.amount = null;
            return;
        }
        let isNegative = false;
        let amountText = this.amountText;
        if (this.amountText[0] === '-') {
            isNegative = true;
            amountText = this.amountText.substring(1);
        }
        if (this.isSeparate) {
            const amount = amountText ? NumberUtil.revertMoneytoNumber(amountText) : null;
            this.amount = this.isDifferentNullAndUndefined(amount) ? amount * (isNegative ? -1 : 1) : null;

            if (this.amount === null) {
                return;
            }
            if (this.isDecimal) {
                this.amount = NumberUtil.decimalAdjust(this.amount, this.maxLengthDecimal);
            } else {
                this.amount = Math.round(this.amount);
            }

            if (this.isInteger) {
                if (this.amount < 0) {
                    this.amount = this.amount * -1;
                }
            }
        } else {
            this.amount = this.amountText || null;
        }
        if (this.amount === null) {
            return;
        }
       
        if (this.maxLength && this.isSeparate) {
            const split = (Math.abs(this.amount) + '').split('.');
            const amountInterger = split[0] || '';
            if (amountInterger.length > this.maxLength) {
                let amount = '';
                for(let i=0 ; i < this.maxLength; i++) {
                    amount+= '9';
                }
                this.amount = split[1] ? amount + '.' + split[1] : amount;
            }
        }
        if (!this.isSeparate) {
            const length = (Math.abs(this.amount) + '').length;
            if (length > this.maxLength) {
                this.amount = this.amount.slice(0, this.maxLength);
                this.cdf.detectChanges();
            }
        }
    }
    changeText() {
        if (!this.isSeparate) {
            this.amountText = this.isDifferentNullAndUndefined(this.amount) ? this.amount + '' : '';
            return;
        }
        let changeText = this.isDifferentNullAndUndefined(this.amount)  ? NumberUtil.numberToMoney(this.amount) : '';
        if (this.checkDifferentText(changeText)) {
            this.amountText = changeText;
        }
    }
    checkDifferentText(changeText) {
        if (changeText === '0') {
            let amountText = this.amountText.replaceAll('.', '');
            amountText = amountText.replaceAll(',', '');
            return parseInt(changeText) !== parseInt(amountText);
        }
        if ('-' === this.amountText) {
            return false;
        }
        if (changeText + '.' === this.amountText) {
           return false;
        }
        return true;
    }
    catchKeydown(event) {
        const key = event.key;
        // input decimal
        if ( this.isDecimal && key === '.') {
            const amount = (this.amountText || '').replaceAll(',', '');
            const positionDots = amount.indexOf('.');
            if (positionDots > -1) {
                event.preventDefault();
            }
            return;
        }

        // input negative
        if ( !this.isInteger && key === '-') {
            return;
        }   

        if (key === 'Backspace' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Tab' || key === 'Delete' ) {
           return;
        }
        if (key === 'Control') {
            this.beforeKeydown = key;
        }

        if ((key === 'v' && this.beforeKeydown === 'Control'  )) {
            this.beforeKeydown = '';
            return;
        }

        if ((key === 'c' && this.beforeKeydown === 'Control'  )) {
            this.beforeKeydown = '';
            return;
        }
        if ((key === 'a' && this.beforeKeydown === 'Control'  )) {
            this.beforeKeydown = '';
            return;
        }
        if ((key === 'x' && this.beforeKeydown === 'Control'  )) {
            this.beforeKeydown = '';
            return;
        }

        if (key >= '0' && key <= '9') {
          
            const selection: any = document.getSelection();
            if (selection.toString() && selection.baseNode.id === 'input-group-' + this.id ) {
                return;
            }
            let amount = (this.amountText || '').replaceAll(',', '');
            let isNegative = false;
            if (amount[0] === '-') {
                isNegative = true;
                amount = amount.substring(1);
            }
            if (!this.isSeparate && amount.length === this.maxLength) {
                event.preventDefault();
                return;
            }
            const textField : any = document.getElementById('input-' + this.id);
            const cursorPosition = textField.selectionStart;
            const positionDots = amount.indexOf('.');
            if (cursorPosition <= positionDots || positionDots === -1) {
                if (this.maxLength) {
                    const lengthNumber = ((amount + '').split('.')[0] || '').length;
                    if (lengthNumber >= this.maxLength) {
                        event.preventDefault();
                        return;
                    }
                }
            } else {
                if (this.isDecimal) {
                    const lengthDecimal = ((amount + '').split('.')[1] || '').length;
                    if (lengthDecimal >= this.maxLengthDecimal) {
                        event.preventDefault();
                        return;
                    }
                }
            }
        }

        if (key < '0' || key > '9') {
            event.preventDefault();
            return;
        }
        return;
    }
    isDifferentNullAndUndefined(amount?) {
        return amount !== null && amount !== undefined;
    }
}
