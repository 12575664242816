import { Injectable } from "@angular/core";
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessPopupComponent } from '../components/success-popup/success-popup';
import { ErrorPopupComponent } from '../components/error-popup/error-popup';
import { ConfirmPopupComponent } from '../components/confirm-popup/confirm-popup';
import { WarningPopupComponent } from 'src/app/common/components/warning-popup/warning-popup';
import { NoConnectInternetComponent } from "../components/no-connect-internet-popup/no-connect-internet-popup";
import { EventManagerService } from "./event-manager.service";
/**
 * declare Jquery
 * */
declare let $: any;

@Injectable({ providedIn: 'root' })
export class DialogService {
  public modals: any = {};

  constructor(
    public eventManager: EventManagerService,
    private modalService: NgbModal
  ) {

  }

  showConfirm(properties) {
    if (!properties.isNoDismissAll) {
      this.modalService.dismissAll();
    }
    this.eventManager.broadcast({name: 'close-modal', content: {}});
    return new Promise<boolean>((resolve, reject) => {
      const options: NgbModalOptions = {};
      options.centered = true;
      options.backdrop = 'static';
      options.windowClass = 'confirm-popup-modal small-modal';
      setTimeout(() => {
        const modalRef = this.modalService.open(ConfirmPopupComponent, options);
        Object.keys(properties).forEach((key) => {
          modalRef.componentInstance[key] = properties[key];
        })
        modalRef.componentInstance.result
        .subscribe((res) => {
          resolve(res);
        })
      }, 200)
    })
  }
  showError(message: any, title?: string, properties?: any) {
    properties = properties || {};
    if (!properties.isNoDismissAll) {
      this.modalService.dismissAll();
    }
    this.eventManager.broadcast({name: 'close-modal', content: {}});
    return new Promise<boolean>((resolve, reject) => {
      const options: NgbModalOptions = {};
      options.centered = true;
      options.backdrop = properties.backdrop;
      options.windowClass  = 'error-popup-modal';

      setTimeout(() => {
        const modalRef = this.modalService.open(ErrorPopupComponent, options);
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.title = title || 'Có lỗi';
        Object.keys(properties).forEach((key) => {
          modalRef.componentInstance[key] = properties[key];
        })
        modalRef.componentInstance.result
        .subscribe((res) => {
          resolve(res);
        });
      }, 200)
    
    })
  }

  showSuccess(message?: string, title?: string, properties?: any) {
    properties = properties || {};
    if (!properties.isNoDismissAll) {
      this.modalService.dismissAll();
    }
    this.eventManager.broadcast({name: 'close-modal', content: {}});
    return new Promise<boolean>((resolve, reject) => {
      const options: NgbModalOptions = {};
      options.centered = true;
      options.backdrop = properties.backdrop;
      options.windowClass = 'success-popup-modal';
      setTimeout(() => {
        const modalRef = this.modalService.open(SuccessPopupComponent, options);
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.title = title || 'Thành công';
        Object.keys(properties).forEach((key) => {
          modalRef.componentInstance[key] = properties[key];
        })
        modalRef.componentInstance.result
        .subscribe((res) => {
          resolve(res);
        })
      }, 200)
      
    })
  }
  showWarning(message?: string, title?: string, properties?: any) {
    properties = properties || {};
    if (!properties.isNoDismissAll) {
      this.modalService.dismissAll();
    }
    return new Promise<boolean>((resolve, reject) => {
      const options: NgbModalOptions = {};
      options.centered = true;
      options.backdrop = properties.backdrop;
      options.windowClass  = 'warning-popup-modal';
      const hasOpenModal = this.modalService.hasOpenModals();
      setTimeout(() => {
        const modalRef = this.modalService.open(WarningPopupComponent, options);
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.title = title || 'Thông báo';
        Object.keys(properties).forEach((key) => {
          modalRef.componentInstance[key] = properties[key];
        })
        modalRef.componentInstance.result
        .subscribe((res) => {
          resolve(res);
        });
      }, 200)
    })
  }
  showNoConnectInternet(message?: string, title?: string, properties?: any) {
    properties = properties || {};
    this.modalService.dismissAll();
    return new Promise<boolean>((resolve, reject) => {
      const options: NgbModalOptions = {};
      options.centered = true;
      options.backdrop = false;
      options.windowClass  = 'no-connect-internet-popup-modal';
      const hasOpenModal = this.modalService.hasOpenModals();
      const modalRef = this.modalService.open(NoConnectInternetComponent, options);
      modalRef.componentInstance.result
      .subscribe((res) => {
        location.reload();
      });
    })
  }
  checkModalExist(modal) {
    if ($('#' + modal).hasClass('show')) {
      return true;
    } else return false;
  }
  destroy() {
    this.modalService.dismissAll();
  }
  showModal(modal, clas?, properties?) {
    const options: NgbModalOptions = {};
    options.centered = true;
    options.windowClass = clas;
    const modalRef = this.modalService.open(modal, options);
    Object.keys(properties || {}).forEach((key) => {
      modalRef.componentInstance[key] = properties[key];
    })
  }
}
