<div class="dropdown" [ngClass]="disabled ? 'disabled' : ''" [style.background-color]="backgroundColor">
  <div class="form-control dropdown-display-value" [ngClass]="isError ? 'border-red' : ''" id="action{{ id }}"
    (click)="open()" [style.min-width]="minWidth">
    <span [style.color]="color">{{ valueShow || name || title }}</span>
  </div>

  <div *ngIf="multiple && name.length > 0 || isLong" id="tooltip" class="tooltip" [ngClass]="tooMany ? 'custom' : ''">
    {{name}}</div>

  <div id="{{ id }}" class="dropdown-content" [ngClass]="openDropdownlist ? 'show' : ''">
    <input class="form-control" type="text" placeholder="Search.." [(ngModel)]="textSearch" (keyup)="filter()" />
    <div class="list-item" *ngIf="groups.length === 0; else haveGroups">
      <a class="record" (click)="chooseValue(defaultItem)" *ngIf="!isNoAll">
        <span>Chọn tất cả / Bỏ chọn</span>
      </a>
      <a class="record" *ngFor="let item of list" [ngClass]="item.isHide ? 'hide' : ''" (click)="chooseValue(item)">
        <div class="record-value">
          <div class="icon-image" *ngIf="item.icon">
            <img [src]="item.icon" style="margin-right: 10px" />
          </div>
          <span>{{ item[field] }}</span>
        </div>
        <span *ngIf="item.isCheck"><i class="fa fa-check" aria-hidden="true"></i></span>
      </a>
    </div>
    <ng-template #haveGroups>
      <div class="list-item">
        <div class="d-flex flex-column" *ngFor="let group of groups">
          <div class="d-flex align-items-center" [ngClass]="group.isHide ? 'hide' : ''">
            <img width="20" height="20" [src]="group.option?.icon" class="mr-2" *ngIf="group.option?.icon" />
            <span class="sub-title" (click)="chooseGroup(group)"><strong>{{group.option?.title}}</strong></span>
          </div>
          <div class="d-flex flex-column">
            <a class="record" *ngFor="let item of group.list" (click)="chooseValue(item)"
              [ngClass]="item.isHide ? 'hide' : ''">
              <img *ngIf="item.icon" [src]="item.icon" height="25" style="margin-right: 10px" />
              <span>{{ item[field] }}</span>
              <span *ngIf="item.isCheck"><i class="fa fa-check" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<span id="backdrop{{id}}" class="backdrop"></span>